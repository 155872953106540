/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { verify } from '../slices/userSlice'
import { fetchMembershipTypes, deleteMembershipType, editMembershipType, addMembershipType } from '../slices/membershipTypeSlice'
import type { MembershipTypeWithNbrOfMembers } from '../../services/MembershipService'
import DataTable, { TableColumn, ConditionalStyles } from 'react-data-table-component'

import Modal from 'react-bootstrap/Modal'

import Navbar from '../components/Navbar'

export default function ManageMembershipTypes() {

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const membership_types = useSelector<RootState, MembershipTypeWithNbrOfMembers[]>(state => state.membership_type.membership_types) ?? []
  const count = useSelector<RootState, number>(state => state.membership_type.count) ?? 0
  const is_fetching = useSelector<RootState, boolean>(state => state.membership_type.is_fetching)
  const is_deleting = useSelector<RootState, boolean>(state => state.membership_type.is_deleting)
  const is_editing = useSelector<RootState, boolean>(state => state.membership_type.is_editing)
  const is_adding = useSelector<RootState, boolean>(state => state.membership_type.is_adding)
  const error_msg = useSelector<RootState, string | null>(state => state.membership_type.error_msg)

  const [deleting, setDeleting] = useState<bigint | null>(null)

  const [editing, setEditing] = useState<bigint | null>(null)
  const [adding, setAdding] = useState<boolean>(false)
  const [modal_form, setModalForm] = useState({
    name: ''
  })

  const columns: TableColumn<MembershipTypeWithNbrOfMembers>[] = [
    {
      name: 'No',
      selector: row => row.id,
      maxWidth: '0.5rem',
      center: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      grow: 2
    },
    {
      name: 'Number of members',
      selector: row => row.nbr_of_members,
    },
    {
      name: '',
      cell: row => (
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => {
            setEditing(row.id)
            setModalForm({
              name: row.name
            })
          }}>
            <i className="bi-pencil"></i>
        </button>
      ),
      maxWidth: '0.5rem',
      compact: true,
      right: true
    },
    {
      name: '',
      cell: row => <button type="button" className="btn btn-danger btn-sm" onClick={() => setDeleting(row.id)}><i className="bi-trash"></i></button>,
      maxWidth: '0.5rem',
      compact: true,
      center: true
    }
  ]

  const conditionalRowStyles: ConditionalStyles<MembershipTypeWithNbrOfMembers>[] = [
    {
      when: row => {
        return row.nbr_of_members === 0
      },
      style: {
        color: 'grey',
      }
    }
  ]

  useEffect(() => {

    // redirect to login page if not logged in
    (async () => {
      const result = await dispatch(verify()).unwrap()
      if (result.username === '') {
        navigate('/login')
      }
    })()

  }, [dispatch, navigate])

  useEffect(() => {
    dispatch(fetchMembershipTypes())
  }, [dispatch])


  const editOrAddModalFormControls = () => {

    const go = adding || editing
    if (!go) return <></>

    return <>
      <div className="mb-3">
        <label htmlFor="edit-type-modal-name" className="form-label">Name</label>
        <input
          type="text"
          className="form-control"
          id="edit-type-modal-name"
          value={modal_form.name}
          onChange={(e) => setModalForm({ ...modal_form, name: e.target.value })}
        />
      </div>
    </>
  }

  return <>
    <Navbar />

    <Modal show={editing !== null || adding} onHide={() => { setEditing(null); setAdding(false) }}>
      <Modal.Header >
        {
          (() => {
            if (editing) {
              return <div>
                Editing
                <span className="text-warning"> {membership_types.find(m => m.id === editing)?.name}</span>
              </div>
            }

            return <>Adding a new membership type</>
          })()
        }
      </Modal.Header>
      <Modal.Body>
        {editOrAddModalFormControls()}
      </Modal.Body>
      <Modal.Footer>
        <>
          <button className="btn btn-outline-secondary" onClick={() => { setEditing(null); setAdding(false) }}>
            Cancel
          </button>
          {editing && <button className="btn btn-warning" onClick={() => {
            dispatch(
              editMembershipType({
                id: editing,
                name: modal_form.name
              })
            )
            setEditing(null)
          }}>
            Edit
          </button>}
          {adding && <button className="btn btn-success" onClick={() => {
            dispatch(addMembershipType(modal_form))
            setAdding(false)
          }}>
            Add
          </button>}
        </>
      </Modal.Footer>
    </Modal>

    <Modal show={deleting !== null} onHide={() => setDeleting(null)}>
      <Modal.Body>
        <>
          Are you sure you want to delete membership type
          <span className="text-danger"> {membership_types.find(m => m.id === deleting)?.name}</span>
          ?
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={() => setDeleting(null)}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={() => {
          deleting && dispatch(deleteMembershipType(deleting))
          setDeleting(null)
        }}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>

    <main className="main container-lg mx-auto mt-3 px-2">

      <h3>Manage membership types</h3>

      {error_msg !== '' && <div className="alert alert-danger alert-dismissible fade show mt-3">
        { error_msg }
      </div>}

      <DataTable
        columns={columns}
        conditionalRowStyles={conditionalRowStyles}
        data={membership_types}
        progressPending={is_fetching || is_deleting || is_editing || is_adding}
        progressComponent={<div className="spinner-border" role="status"></div>}
        highlightOnHover
      />
      <div className="d-flex justify-content-between mt-4">
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            setAdding(true);
            setModalForm({
              name: '',
            })
          }}>
            Add new membership type <i className="bi-file-earmark-plus-fill"></i>
        </button>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <div className="d-flex align-items-center me-3">Showing {count} membership types</div>
      </div>
    </main>
  </>
}
