/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { verify } from '../slices/userSlice'
import { fetchMembers, deleteMember, addMember, editMember, uploadAvatar } from '../slices/memberSlice'
import type { MemberAvatarState } from '../slices/memberSlice'
import { fetchMembershipTypes } from '../slices/membershipTypeSlice'
import type { MemberWithMembershipType } from '../../services/MemberService'
import DataTable, { TableColumn, ConditionalStyles } from 'react-data-table-component'
import { DateTime } from 'luxon'

import Modal from 'react-bootstrap/Modal'

import MemberAddEditModal from '../components/MemberAddEditModal'

import Navbar from '../components/Navbar'

const UploaderButton: React.FC<{ member: MemberWithMembershipType & MemberAvatarState }> = ({ member }) => {

  const dispatch = useDispatch<AppDispatch>()
  const inputRef = useRef<HTMLInputElement>(null)

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      await dispatch(uploadAvatar({ id: member.id, file }))
    }
  }

  return <>
    <input ref={inputRef} className="visually-hidden" type="file" accept="image/*" onChange={onFileChange} />
    <button
      type="button"
      className="btn btn-success btn-sm"
      onClick={() => {
        if (member.is_uploading_avatar) return
        if (!inputRef.current) return
        inputRef.current.click()
      }}
      disabled={member.is_uploading_avatar}
      >
        {member.is_uploading_avatar && <div className="spinner-border spinner-border-sm"></div>}
        {!member.is_uploading_avatar && <i className="bi-upload"></i>}
    </button>
  </>
}

export default function ManageMembers() {

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const MEMBERS_PER_PAGE = 30
  const members = useSelector<RootState, (MemberWithMembershipType & MemberAvatarState)[]>(state => state.member.members) ?? []
  // const membership_types = useSelector<RootState, MembershipTypeWithNbrOfMembers[]>(state => state.membership_type.membership_types) ?? []
  const count = useSelector<RootState, number>(state => state.member.count) ?? 0
  // const limit = useSelector<RootState, number>(state => state.member.limit) ?? MEMBERS_PER_PAGE
  const offset = useSelector<RootState, number>(state => state.member.offset) ?? 0
  const is_fetching = useSelector<RootState, boolean>(state => state.member.is_fetching)
  const is_deleting = useSelector<RootState, boolean>(state => state.member.is_deleting)
  const is_editing = useSelector<RootState, boolean>(state => state.member.is_editing)
  const is_adding = useSelector<RootState, boolean>(state => state.member.is_adding)
  const error_msg = useSelector<RootState, string>(state => state.member.error_msg)

  const [deleting, setDeleting] = useState<bigint | null>(null)

  const [editing, setEditing] = useState<bigint | null>(null)
  const [adding, setAdding] = useState<boolean>(false)

  const [showQr, setShowQr] = useState<bigint | null>(null)
  const [isQrLoading, setIsQrLoading] = useState<boolean>(false)
  const [qrError, setQrError] = useState<boolean>(false)

  const columns: TableColumn<(MemberWithMembershipType & MemberAvatarState)>[] = [
    {
      name: 'No',
      selector: row => row.membership_number,
      maxWidth: '0.5rem',
    },
    {
      name: 'Name',
      selector: row => row.name,
      grow: 2
    },
    {
      name: 'Phone number',
      selector: row => row.phone_number
    },
    {
      name: 'Membership type',
      selector: row => row.membership_type.name,
      grow: 2
    },
    {
      name: 'Membership since',
      selector: row => DateTime.fromISO(row.membership_start_date as unknown as string).toFormat('dd LLL yyyy'),
    },
    {
      name: 'expires',
      selector: row => DateTime.fromISO(row.membership_start_date as unknown as string).plus({ year: 1 }).toFormat('dd LLL yyyy'),
    },
    {
      name: '',
      cell: row => (
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => {
            setShowQr(row.id)
            setIsQrLoading(true)
          }}>
            <i className="bi-qr-code"></i>
        </button>
      ),
      width: '48px',
      compact: true,
      center: true
    },
    {
      name: '',
      cell: row => <UploaderButton member={row} />,
      width: '48px',
      compact: true,
      center: true
    },
    {
      name: '',
      cell: row => (
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => setEditing(row.id)}>
          <i className="bi-pencil"></i>
        </button>
      ),
      width: '48px',
      compact: true,
      center: true
    },
    {
      name: '',
      cell: row => <button type="button" className="btn btn-danger btn-sm" onClick={() => setDeleting(row.id)}><i className="bi-trash"></i></button>,
      width: '48px',
      compact: true,
      center: true
    }
  ]

  const conditionalRowStyles: ConditionalStyles<(MemberWithMembershipType & MemberAvatarState)>[] = [
    {
      when: row => {
        const expiry_date = DateTime.fromISO(row.membership_start_date as unknown as string).plus({ year: 1 }).startOf('day')
        const now = DateTime.now().plus({ month: 1 }).startOf('day')
        return expiry_date < now
      },
      style: {
        color: 'red',
      }
    }
  ]

  useEffect(() => {

    // redirect to login page if not logged in
    (async () => {
      const result = await dispatch(verify()).unwrap()
      if (result.username === '') {
        navigate('/login')
      }
    })()

  }, [dispatch, navigate])

  useEffect(() => {
    dispatch(fetchMembers({ limit: MEMBERS_PER_PAGE, offset: 0 }))
    dispatch(fetchMembershipTypes())
  }, [dispatch])

  const goPrevious = () => {
    dispatch(fetchMembers({ limit: MEMBERS_PER_PAGE, offset: offset - MEMBERS_PER_PAGE }))
  }

  const goNext = () => {
    dispatch(fetchMembers({ limit: MEMBERS_PER_PAGE, offset: offset + MEMBERS_PER_PAGE }))
  }

  return <>
    <Navbar />

    <Modal show={showQr !== null} onHide={() => setShowQr(null)}>
      <Modal.Header closeButton>
        <Modal.Title>
          Membership card for
          <span className="text-secondary"> {members.find(m => m.id === showQr)?.name}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {(() => {

          if (showQr === null) return <></>

          if (qrError) {
            return <div className="alert alert-danger alert-dismissible fade show">
              Failed to load QR code, please try again later
            </div>
          }

          const member = members.find(m => m.id === showQr)
          if (!member) {
            return <></>
          }

          // s3 cached url
          // TODO: hardcoded url
          const url = `https://pub-f5db4ed0eac44d478b0a472c22812417.r2.dev/bspaddling-cache/qr/${member.id}_${member.membership_number}_${member.membership_type_id}.png`

          return <>
            {isQrLoading && <div className="spinner-border" role="status"></div>}
            <img
              className="w-100"
              src={url}
              alt={showQr.toString()}
              onLoad={() => {
                setQrError(false)
                setIsQrLoading(false)
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = () => {
                  setQrError(true)
                  setIsQrLoading(false)
                }
                currentTarget.src = `/.netlify/functions/qr/${showQr}`
              }}
            />
          </>
        })()}
      </Modal.Body>
    </Modal>

    <MemberAddEditModal
      editing={editing}
      editingMember={members.find(m => m.id === editing)}
      adding={adding}
      onHide={() => {
        setEditing(null)
        setAdding(false)
      }}
      onAddClicked={(form) => {
        dispatch(addMember(form))
        setAdding(false)
      }}
      onEditClicked={(form) => {
        dispatch(
          editMember({
            id: editing!,
            ...form
          })
        )
        setEditing(null)
      }}
      />

    <Modal show={deleting !== null} onHide={() => setDeleting(null)}>
      <Modal.Body>
        <>
          Are you sure you want to delete member
          <span className="text-danger"> {members.find(m => m.id === deleting)?.name}</span>
          ?
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={() => setDeleting(null)}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={() => {
          deleting && dispatch(deleteMember(deleting))
          setDeleting(null)
        }}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>

    <main className="main container-lg mx-auto mt-3 px-2">

      <h3>Manage members</h3>

      {error_msg !== '' && <div className="alert alert-danger alert-dismissible fade show mt-3">
        { error_msg }
      </div>}

      <DataTable
        columns={columns}
        conditionalRowStyles={conditionalRowStyles}
        data={members}
        progressPending={is_fetching || is_deleting || is_editing || is_adding}
        progressComponent={<div className="spinner-border" role="status"></div>}
        highlightOnHover
      />
      <div className="d-flex justify-content-between mt-4">
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={() => setAdding(true)}>
          Add new member <i className="bi-person-fill-add"></i>
        </button>
      </div>

      <div className="d-flex align-items-center mt-3 text-danger" style={{ fontSize: '0.75rem' }}>* Members that expiring in less than 1 month will be shown in red</div>

      <div className="d-flex justify-content-end mt-3">
        <div className="d-flex align-items-center me-3">Showing from {offset + 1} - {offset + members.length} of {count} members</div>

        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" style={{ cursor: 'pointer' }} onClick={goPrevious}>
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" style={{ cursor: 'pointer' }} onClick={goNext}>
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </div>
    </main>
  </>
}
