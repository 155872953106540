/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { verify } from '../slices/userSlice'
import { fetchCheckinsByYear, editCheckin } from '../slices/checkinSlice'
import type { CheckinWithMember } from '../../services/CheckinService'
import DataTable, { TableColumn } from 'react-data-table-component'
import { DateTime } from 'luxon'

import CheckinAddEditModal from '../components/CheckinAddEditModal'

import Navbar from '../components/Navbar'

export default function History() {

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const CHECKINS_PER_PAGE = 30
  const checkins = useSelector<RootState, CheckinWithMember[]>(state => state.checkin.checkins) ?? []
  const count = useSelector<RootState, number>(state => state.checkin.count) ?? 0
  const offset = useSelector<RootState, number>(state => state.checkin.offset) ?? 0
  const currentYear = useSelector<RootState, number>(state => state.checkin.currentYear) ?? DateTime.now().year
  const is_fetching = useSelector<RootState, boolean>(state => state.checkin.is_fetching)
  const is_editing = useSelector<RootState, boolean>(state => state.checkin.is_editing)
  const error_msg = useSelector<RootState, string | null>(state => state.checkin.error_msg)

  const [editing, setEditing] = useState<bigint | null>(null)

  const columns: TableColumn<CheckinWithMember>[] = [
    {
      name: 'Date',
      selector: row => DateTime.fromISO(row.checkin_time as unknown as string).toFormat('dd LLL yyyy'),
    },
    {
      name: 'No',
      selector: row => row.member.membership_number,
    },
    {
      name: 'Member',
      selector: row => row.member.name,
      grow: 2,
    },
    {
      name: 'Membership Type',
      selector: row => row.member.membership_type.name,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks ?? '',
      grow: 2,
    },
    {
      name: '',
      cell: row => (
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => setEditing(row.id)}>
            <i className="bi-pencil"></i>
        </button>
      ),
      maxWidth: '0.5rem',
      compact: true,
      center: true
    },
  ]

  useEffect(() => {

    // redirect to login page if not logged in
    (async () => {
      const result = await dispatch(verify()).unwrap()
      if (result.username === '') {
        navigate('/login')
      }
    })()

  }, [dispatch, navigate])

  useEffect(() => {
    dispatch(fetchCheckinsByYear({ year: currentYear, limit: CHECKINS_PER_PAGE, offset: 0 }))
  }, [dispatch, currentYear])

  const goPrevious = () => {
    dispatch(fetchCheckinsByYear({ year: currentYear, limit: CHECKINS_PER_PAGE, offset: offset - CHECKINS_PER_PAGE }))
  }

  const goNext = () => {
    dispatch(fetchCheckinsByYear({ year: currentYear, limit: CHECKINS_PER_PAGE, offset: offset + CHECKINS_PER_PAGE }))
  }

  return <>
    <Navbar />

    <CheckinAddEditModal
      editing={editing}
      editingCheckin={checkins.find(checkin => checkin.id === editing)}
      adding={false}
      onHide={() => setEditing(null)}
      onEditClicked={(form) => {
        dispatch(editCheckin({ id: editing!, remarks: form.remarks }))
        setEditing(null)
      }}
      onAddClicked={() => {}}
      />

    <main className="main container-lg mx-auto mt-3 px-2">

      <h3>History</h3>

      {error_msg !== '' && <div className="alert alert-danger alert-dismissible fade show mt-3">
        { error_msg }
      </div>}

      <DataTable
        columns={columns}
        data={checkins}
        progressPending={is_fetching || is_editing}
        progressComponent={<div className="spinner-border" role="status"></div>}
        highlightOnHover
      />

      <div className="d-flex justify-content-end mt-3">
        <div className="d-flex align-items-center me-3">Showing from {offset + 1} - {offset + checkins.length} of {count} entries</div>

        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" style={{ cursor: 'pointer' }} onClick={goPrevious}>
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" style={{ cursor: 'pointer' }} onClick={goNext}>
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </div>
    </main>
  </>
}
