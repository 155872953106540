import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import type { JWTUser } from '../slices/userSlice'
import { verify } from '../slices/userSlice'

interface NavbarProps {
  hideUser?: boolean
}

export default function Navbar(props: NavbarProps) {

  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector<RootState, JWTUser>(state => state.user.user)
  const is_verifying = useSelector<RootState, boolean>(state => state.user.is_verifying)

  useEffect(() => {

    // verify logged in user on page change
    dispatch(verify())

  }, [dispatch, location])

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img alt="Bangsaen Paddling Club" src="/logo192.jpg" style={{ height: 52 }} />
          </Link>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/history">History</Link>
            </li>
          </ul>


          {!!!props.hideUser && !is_verifying && (
            <ul className="nav justify-content-end">
              {user.username !== '' &&
                <li className="nav-item dropdown">
                  <button className="nav-link btn btn-link text-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi-person-fill"></i>
                    <span className="ps-1">{ user.username }</span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><Link className="dropdown-item" to="/manage/members">Manage members</Link></li>
                    <li><Link className="dropdown-item" to="/manage/membership_types">Manage membership Types</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                  </ul>
                </li>
              }
              {user.username === '' && <li className="nav-item">
                <Link className="nav-link" to="/login">Login</Link>
              </li>}
            </ul>
          )}
        </div>
      </nav>
    </>
  )
}
