import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import Frontpage from './Frontpage'
import Login from './Login'
import Logout from './Logout'
import ManageMembers from './ManageMembers'
import ManageMembershipTypes from './ManageMembershipTypes'
import Qr from './Qr'
import History from './History'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Frontpage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/history" element={<History />} />
        <Route path="/manage/members" element={<ManageMembers />} />
        <Route path="/manage/membership_types" element={<ManageMembershipTypes />} />
        <Route path="/qr/:id" element={<Qr />} />
      </Routes>
    </Router>
  )
}

export default App
