import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { z } from 'zod'
import { DateTime } from 'luxon'

import { FetchCheckinsQuery, AddCheckinForm, EditCheckinForm } from '../../netlify/functions/checkins'
import type { FetchCheckinsResponse, AddCheckinResponse, EditCheckinResponse } from '../../netlify/functions/checkins'
import type { CheckinWithMember } from '../../services/CheckinService'

export type CheckinSliceState = {
  checkins: CheckinWithMember[]
  count: number
  offset: number
  limit: number
  currentYear: number
  is_fetching: boolean
  is_editing: boolean
  is_adding: boolean
  error_msg: string
}

export const fetchCheckinsByYear = createAsyncThunk<FetchCheckinsResponse, { year: number } & z.infer<typeof FetchCheckinsQuery>>('checkin/fetchByYear', async ({ year, offset, limit }) => {
  const result = await fetch(`/.netlify/functions/checkins/${year}?offset=${offset}&limit=${limit}`)
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<FetchCheckinsResponse>
})

export const editCheckin = createAsyncThunk<EditCheckinResponse, { id: bigint } & z.infer<typeof EditCheckinForm>>('checkin/edit', async (checkin) => {
  const result = await fetch(`/.netlify/functions/checkins/${checkin.id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...checkin, id: undefined }),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<EditCheckinResponse>
})

export const addCheckin = createAsyncThunk<AddCheckinResponse, { member_id: bigint } & z.infer<typeof AddCheckinForm>>('checkin/add', async (checkin) => {
  const result = await fetch(`/.netlify/functions/checkins/${checkin.member_id}`, {
    method: 'POST',
    body: JSON.stringify({ ...checkin, id: undefined }),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<AddCheckinResponse>
})

export const checkinSlice = createSlice({
  name: 'checkin',
  initialState: {
    checkins: [],
    count: 0,
    offset: 0,
    limit: 10,
    currentYear: DateTime.now().year,

    is_fetching: false,
    is_editing: false,
    is_adding: false,
    error_msg: '',
  } as CheckinSliceState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckinsByYear.pending, (state) => {
        state.is_fetching = true
      })
      .addCase(fetchCheckinsByYear.rejected, (state, action) => {
        state.is_fetching = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(fetchCheckinsByYear.fulfilled, (state, action) => {
        state.is_fetching = false
        state.error_msg = ''
        state.checkins = action.payload.checkins
        state.count = action.payload.count
        state.offset = action.payload.offset
        state.limit = action.payload.limit
        state.currentYear = action.meta.arg.year
      })

      .addCase(editCheckin.pending, (state) => {
        state.is_editing = true
      })
      .addCase(editCheckin.rejected, (state, action) => {
        state.is_editing = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(editCheckin.fulfilled, (state, action) => {
        state.is_editing = false
        state.error_msg = ''

        const index = state.checkins.findIndex((type) => type.id === action.payload?.id)
        if (index < 0) return

        const newCheckins = [...state.checkins]
        newCheckins[index] = {
          ...newCheckins[index],
          remarks: action.payload.remarks ?? '',
        }

        state.checkins = newCheckins
      })

      .addCase(addCheckin.pending, (state) => {
        state.is_adding = true
      })
      .addCase(addCheckin.rejected, (state, action) => {
        state.is_adding = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(addCheckin.fulfilled, (state, action) => {
        state.is_adding = false
        state.error_msg = ''

        if (DateTime.fromISO(action.payload.checkin_time as unknown as string).year === state.currentYear) {
          const newCheckins = [
            ...state.checkins,
            action.payload,
          ]
          state.checkins = newCheckins
        }
      })
  },
})

export default checkinSlice.reducer
