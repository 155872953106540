import { useEffect, useState, useMemo } from 'react'
import type { CheckinWithMember } from '../../services/CheckinService'
import { DateTime } from 'luxon'

import Modal from 'react-bootstrap/Modal'

export interface CheckinAddEditModalFormState {
  remarks: string
}

export const memberAddEditModalDefaultForm: CheckinAddEditModalFormState = {
  remarks: '',
}

interface CheckinAddEditModalProps {
  editing: bigint | null
  editingCheckin?: CheckinWithMember
  adding: boolean
  onHide: () => void
  onAddClicked: (form: CheckinAddEditModalFormState) => void
  onEditClicked: (form: CheckinAddEditModalFormState) => void
}

const CheckinAddEditModal = ({ editing, editingCheckin, adding, onHide, onAddClicked, onEditClicked }: CheckinAddEditModalProps) => {

  const initialForm = useMemo(() => {
    if (adding || editing === null) {
      return memberAddEditModalDefaultForm
    }

    return {
      remarks: editingCheckin!.remarks ?? '',
    }
  }, [editing, adding, editingCheckin])

  const [form, setForm] = useState<CheckinAddEditModalFormState>(initialForm)
  useEffect(() => {
    setForm(initialForm)
  }, [editing, adding, editingCheckin, initialForm])

  return (
    <Modal show={editing !== null || adding} onHide={onHide}>
      <Modal.Header closeButton>
        {
          (() => {
            if (editing) {
              return <div>Editing a check-in</div>
            }

            return <>Adding a check-in</>
          })()
        }
      </Modal.Header>
      <Modal.Body>
        {editingCheckin && <>
          <div className="mb-3">
            <input
              type="date"
              className="form-control"
              value={DateTime.fromISO(editingCheckin?.checkin_time as unknown as string).toFormat('yyyy-MM-dd')}
              disabled
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              value={editingCheckin?.member.name}
              disabled
            />
          </div>
        </>}
        <div className="mb-3">
          <label htmlFor="edit-checkin-modal-remarks" className="form-label">Remarks</label>
          <input
            type="text"
            className="form-control"
            id="edit-checkin-modal-remarks"
            value={form.remarks}
            onChange={(e) => setForm({ ...form, remarks: e.target.value })}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <>
          <button className="btn btn-outline-secondary" onClick={onHide}>
            Cancel
          </button>
          {editing && <button className="btn btn-warning" onClick={() => onEditClicked(form)}>
            Edit
          </button>}
          {adding && <button className="btn btn-success" onClick={() => onAddClicked(form)}>
            Add
          </button>}
        </>
      </Modal.Footer>
    </Modal>
  )
}

export default CheckinAddEditModal
