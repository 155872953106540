import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { z } from 'zod'

import { FetchMembersQuery, EditMemberForm } from '../../netlify/functions/members'
import type { FetchMembersResponse, DeleteMemberResponse, EditMemberResponse, AddMemberResponse } from '../../netlify/functions/members'
import type { MemberWithMembershipType, MemberWithCheckins } from '../../services/MemberService'

export interface MemberAvatarState {
  is_uploading_avatar: boolean
}

export type MemberSliceState = {
  members: (MemberWithMembershipType & MemberWithCheckins & MemberAvatarState)[]
  count: number
  offset: number
  limit: number
  is_fetching: boolean
  is_deleting: boolean
  is_editing: boolean
  is_adding: boolean
  error_msg: string
}

export const fetchMembers = createAsyncThunk<FetchMembersResponse, z.infer<typeof FetchMembersQuery>>('members/fetch', async ({ offset, limit }) => {
  const result = await fetch(`/.netlify/functions/members?offset=${offset}&limit=${limit}`)
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<FetchMembersResponse>
})

export const deleteMember = createAsyncThunk<DeleteMemberResponse, bigint>('members/delete', async (id) => {
  const result = await fetch(`/.netlify/functions/members/${id}`, {
    method: 'DELETE',
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<DeleteMemberResponse>
})

export const editMember = createAsyncThunk<EditMemberResponse, { id: bigint } & z.infer<typeof EditMemberForm>>('members/edit', async (member) => {
  const result = await fetch(`/.netlify/functions/members/${member.id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...member, id: undefined }),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<EditMemberResponse>
})

export const addMember = createAsyncThunk<AddMemberResponse, z.infer<typeof EditMemberForm>>('members/add', async (member) => {
  const result = await fetch(`/.netlify/functions/members`, {
    method: 'POST',
    body: JSON.stringify({ ...member, id: undefined }),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<AddMemberResponse>
})

const MAX_IMAGE_SIZE = 1024 * 1024 * 5 // 5MB
export const uploadAvatar = createAsyncThunk<void, { id: bigint, file: File }>('members/avatar/upload', async ({ id, file }) => {

  if (!/image\/*/.test(file.type)) {
    throw new Error('File must be an image')
  }

  if (file.size > MAX_IMAGE_SIZE) {
    throw new Error(`Image size must be less than ${MAX_IMAGE_SIZE} bytes`)
  }

  // get signed url
  const result = await fetch(`/.netlify/functions/avatars/${id}`, {
    method: 'PUT'
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  const signed_url = await result.text()
  await fetch(signed_url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type
    },
    body: file
  })
})

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    members: [],
    count: 0,
    offset: 0,
    limit: 10,
    is_fetching: false,
    is_deleting: false,
    is_editing: false,
    is_adding: false,
    error_msg: '',
  } as MemberSliceState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.is_fetching = true
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.is_fetching = false
        state.error_msg = action.error.message ?? 'Unknown error'
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.is_fetching = false
        state.error_msg = ''
        state.members = action.payload.members.map(m => ({ ...m, is_uploading_avatar: false }))
        state.count = action.payload.count
        state.offset = action.payload.offset
        state.limit = action.payload.limit
      })

      .addCase(deleteMember.pending, (state) => {
        state.is_deleting = true
      })
      .addCase(deleteMember.rejected, (state, action) => {
        state.is_deleting = false
        state.error_msg = action.error.message ?? 'Unknown error'
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.is_deleting = false
        state.error_msg = ''

        const index = state.members.findIndex((member) => member.id === action.payload?.id)
        if (index < 0) return

        const newMembers = [...state.members]
        newMembers.splice(index, 1)

        state.members = newMembers
        state.count = state.count - 1
      })

      .addCase(editMember.pending, (state) => {
        state.is_editing = true
      })
      .addCase(editMember.rejected, (state, action) => {
        state.is_editing = false
        state.error_msg = action.error.message ?? 'Unknown error'
      })
      .addCase(editMember.fulfilled, (state, action) => {
        state.is_editing = false
        state.error_msg = ''

        const index = state.members.findIndex((member) => member.id === action.payload?.id)
        if (index < 0) return

        const newMembers = [...state.members]
        newMembers[index] = {
          ...newMembers[index],
          ...action.payload,
          membership_start_date: action.payload.membership_start_date as unknown as Date,
        }

        state.members = newMembers
      })

      .addCase(addMember.pending, (state) => {
        state.is_adding = true
      })
      .addCase(addMember.rejected, (state, action) => {
        state.is_adding = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(addMember.fulfilled, (state, action) => {
        state.is_adding = false
        state.error_msg = ''

        const newMembers = [
          ...state.members,
          {
            ...action.payload,
            is_uploading_avatar: false,
          }
        ]
        state.members = newMembers
        state.count = state.count + 1
      })

      .addCase(uploadAvatar.pending, (state, action) => {
        const newMembers = [...state.members]
        const member = newMembers.findIndex(m => m.id === action.meta.arg.id)
        if (member < 0) return

        newMembers[member].is_uploading_avatar = true
        state.members = newMembers
      })
      .addCase(uploadAvatar.rejected, (state, action) => {
        const newMembers = [...state.members]
        const member = newMembers.findIndex(m => m.id === action.meta.arg.id)
        if (member < 0) return

        newMembers[member].is_uploading_avatar = false
        state.members = newMembers

        state.error_msg = action.error.message ?? ''
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.error_msg = ''

        const newMembers = [...state.members]
        const member = newMembers.findIndex(m => m.id === action.meta.arg.id)
        if (member < 0) return

        newMembers[member].is_uploading_avatar = false
        state.members = newMembers
      })
  },
})

export default memberSlice.reducer
