import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginForm } from '../../netlify/functions/login'
import { z } from 'zod'

export type JWTUser = {
  username: string
  name: string
  email: string
}

export type UserSliceState = {
  user: JWTUser
  is_verifying: boolean
  is_logging_in: boolean
}

export const verify = createAsyncThunk<JWTUser>('user/verify', async () => {
  const result = await fetch("/.netlify/functions/verify")
  if (!result.ok) {
    return {
      username: '',
      name: '',
      email: '',
    }
  }

  const json = await result.json()
  return {
    username: json.username,
    name: json.name,
    email: json.email,
  }
})

export const login = createAsyncThunk<JWTUser, z.infer<typeof LoginForm>>('user/login', async ({ username, password }) => {
  const result = await fetch("/.netlify/functions/login", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  })

  if (!result.ok) {
    return {
      username: '',
      name: '',
      email: '',
    }
  }

  const json = await result.json()
  return {
    username: json.username,
    name: json.name,
    email: json.email,
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      username: '',
      name: '',
      email: '',
    },
    is_verifying: false,
    is_logging_in: false,
  } as UserSliceState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(verify.pending, (state) => {
        state.is_verifying = true
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.user = action.payload
        state.is_verifying = false
      })

      .addCase(login.pending, (state) => {
        state.is_logging_in = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload
        state.is_logging_in = false
      })
  },
})

export default userSlice.reducer
