import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import type { JWTUser } from '../slices/userSlice'
import { verify } from '../slices/userSlice'
import { addCheckin } from '../slices/checkinSlice'
import type { MemberWithMembershipType, MemberWithCheckins } from '../../services/MemberService'
import type { checkins as Checkin } from '@prisma/client'
import CheckinAddEditModal from '../components/CheckinAddEditModal'
import DataTable, { TableColumn, ConditionalStyles } from 'react-data-table-component'
import { DateTime } from 'luxon'

import Navbar from '../components/Navbar'

export default function Qr() {

  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector<RootState, JWTUser>(state => state.user.user)
  const is_verifying = useSelector<RootState, boolean>(state => state.user.is_verifying)

  const [error_msg, setErrorMsg] = useState<string>('')
  const [is_verifying_qr, setIsVerifyingQr] = useState<boolean>(false)

  const [member, setMember] = useState<MemberWithMembershipType & MemberWithCheckins | { membership_start_date: string } | null>(null)
  const checkins = useMemo(() => member && 'checkins' in member ? member.checkins : [], [member])

  const [adding, setAdding] = useState<bigint | null>(null)
  const is_checking_in = useSelector<RootState, boolean>(state => state.checkin.is_adding)

  const verifyQr = async () => {
    try {
      setIsVerifyingQr(true)

      const response = await fetch(`/.netlify/functions/qr/${encodeURIComponent(window.location.href)}`, {
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Failed to verify QR code')
      }

      setMember(await response.json() as (MemberWithMembershipType & MemberWithCheckins))

    } catch (e) {
      const error = e as Error
      setErrorMsg(error.message)
      setMember(null)
    } finally {
      setIsVerifyingQr(false)
    }
  }

  useEffect(() => {

    (async () => {
      await dispatch(verify())
      verifyQr()
    })()

  }, [dispatch])

  const columns: TableColumn<Checkin>[] = [
    {
      name: 'Date',
      selector: row => DateTime.fromISO(row.checkin_time as unknown as string).toFormat('dd LLL yyyy'),
    },
    {
      name: 'Remarks',
      selector: row => row.remarks || '',
      grow: 2
    },
  ]

  const conditionalRowStyles: ConditionalStyles<Checkin>[] = [
    {
      when: row => DateTime.fromISO(row.checkin_time as unknown as string).hasSame(DateTime.now(), 'day'),
      style: {
        color: 'green',
      }
    }
  ]

  return <>
    <Navbar />

    <CheckinAddEditModal
      editing={null}
      adding={adding !== null}
      onHide={() => setAdding(null)}
      onEditClicked={() => {}}
      onAddClicked={async (form) => {
        if (member === null || !('id' in member)) {
          return
        }

        const m = member as unknown as MemberWithMembershipType
        await dispatch(addCheckin({ member_id: m.id, remarks: form.remarks }))
        verifyQr()
        setAdding(null)
      }}
      />

    <main className="main container-lg mx-auto mt-3 px-2">

      {error_msg !== '' && <div className="alert alert-danger alert-dismissible fade show mt-3">
        { error_msg }
      </div>}

      {(is_verifying || is_verifying_qr || is_checking_in) && <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}

      {(!is_verifying && !is_verifying_qr && !is_checking_in) && (() => {

        if (member === null) {
          return <></>
        }

        const m = member as MemberWithMembershipType
        return <div className="row">
          <div className="col">
            {user.username !== '' && <>
            <p>
              <img
                src={`https://pub-24e2d65544e24f2aa26ca92856f69d93.r2.dev/bspaddling-assets/avatars/${m.id}`}
                style={{ maxWidth: '150px' }}
                alt=""
                />
            </p>
            <p><b>Membership number:</b> {m.membership_number}</p>
            <p><b>Membership type:</b> {m.membership_type.name}</p>
            <p><b>Name:</b> {m.name}</p>
            </>}
            <p><b>Membership start:</b> {DateTime.fromISO(m.membership_start_date as unknown as string).toFormat('dd LLL yyyy')}</p>
            <p><b>Membership end:</b> {DateTime.fromISO(m.membership_start_date as unknown as string).plus({ year: 1 }).toFormat('dd LLL yyyy')}</p>
            <p>
              {
                // hide check-in button if member has already checked in today
                member &&
                'checkins' in member &&
                !member.checkins.find(checkin => DateTime.fromISO(checkin.checkin_time as unknown as string).hasSame(DateTime.now(), 'day')) &&
                <button className="btn btn-lg btn-primary" onClick={() => setAdding(m.id)}>Check-in</button>
              }
            </p>
            {
              // show message if member has already checked in today
              member &&
              'checkins' in member &&
              member.checkins.find(checkin => DateTime.fromISO(checkin.checkin_time as unknown as string).hasSame(DateTime.now(), 'day')) &&
              <p className="text-success">This member already checked-in for today</p>
            }
          </div>

          <div className="col">
            <div className="row">
              <h3>History</h3>
            </div>

            <div className="row">
              <DataTable
                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
                data={checkins}
                progressPending={is_verifying || is_verifying_qr}
                progressComponent={<div className="spinner-border" role="status"></div>}
              />
            </div>
          </div>
        </div>
      })()}
    </main>
  </>
}

