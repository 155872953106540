import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { verify, login } from '../slices/userSlice'

import Navbar from '../components/Navbar'

export default function Login() {

  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const is_logging_in = useSelector<RootState, boolean>(state => state.user.is_logging_in)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {

    // redirect to login page if not logged in
    (async () => {
      const result = await dispatch(verify()).unwrap()
      if (result.username !== '') {
        setErrorMsg('Already logged in, redirecting...')
        navigate('/')
      }
    })()

  }, [dispatch, navigate])

  const formOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = await dispatch(login({ username, password })).unwrap()
    if (result.username !== '') {
      navigate('/')
    }
  }

  return <>
    <Navbar hideUser={true} />

    <main className="main container-lg mx-auto mt-3 px-2">
      <form onSubmit={formOnSubmit}>
        <h1 className="h3 mb-3 fw-normal">Login</h1>

        {errorMsg !== '' && <div className="alert alert-danger" role="alert">{ errorMsg }</div>}

        <div className="form-floating mb-2">
          <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <label htmlFor="username">Username</label>
        </div>
        <div className="form-floating mb-2">
          <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <label htmlFor="password">Password</label>
        </div>

        {/* <div className="mb-2">
          <Link to="/register">Register a new account</Link>
        </div> */}

        <button className="w-100 btn btn-lg btn-primary" type="submit">
          Login
          {is_logging_in && <div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>}
        </button>
      </form>
    </main>
  </>
}
