import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import type { JWTUser } from '../slices/userSlice'
import { verify } from '../slices/userSlice'
import { useZxing } from 'react-zxing'

import Navbar from '../components/Navbar'

export default function Frontpage() {

  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector<RootState, JWTUser>(state => state.user.user)
  const is_verifying = useSelector<RootState, boolean>(state => state.user.is_verifying)

  const [paused, setPaused] = useState<boolean>(true)

  const { ref } = useZxing({
    onResult: async (result) => {
      setPaused(true)

      try {
        const qrcode = new URL(result.getText())

        // TODO: don't work on local
        // if (qrcode.origin !== window.location.origin || !qrcode.pathname.startsWith('/qr/')) {
        if (!qrcode.pathname.startsWith('/qr/')) {
          throw new Error('Invalid QR code')
        }

        navigate(qrcode.pathname)

      } finally {
        setPaused(false)
      }
    },
    paused: paused,
  })

  useEffect(() => {

    // redirect to login page if not logged in
    (async () => {
      const result = await dispatch(verify()).unwrap()
      if (result.username === '') {
        navigate('/login')
      }
    })()

  }, [dispatch, navigate])

  return <>
    <Navbar />

    <main className="main container-lg mx-auto mt-3 px-2">
      <h2>Frontpage</h2>

      {is_verifying && <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}

      {user.username !== '' && <>
        <div className="text-center mb-3">
          <button className="btn btn-lg btn-primary" onClick={() => setPaused(!paused)}>
            {paused &&<i className="bi-qr-code-scan"></i>}
            {!paused &&<i className="bi-pause-circle-fill"></i>}
          </button>
        </div>
        <video className="mx-auto w-100" style={{ minHeight: '400px' }} ref={ref} />
      </>}
    </main>
  </>
}

