import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { z } from 'zod'

import { EditMembershipTypeForm, AddMembershipTypeForm } from '../../netlify/functions/membership_types'
import type { FetchMembershipTypesResponse, DeleteMembershipTypeResponse, EditMembershipTypeResponse, AddMembershipTypeResponse } from '../../netlify/functions/membership_types'
import type { MembershipTypeWithNbrOfMembers } from '../../services/MembershipService'

export type MembershipTypeSliceState = {
  membership_types: MembershipTypeWithNbrOfMembers[]
  count: number
  is_fetching: boolean
  is_deleting: boolean
  is_editing: boolean
  is_adding: boolean
  error_msg: string
}

export const fetchMembershipTypes = createAsyncThunk<FetchMembershipTypesResponse>('membershiptype/fetch', async () => {
  const result = await fetch(`/.netlify/functions/membership_types`)
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<FetchMembershipTypesResponse>
})

export const deleteMembershipType = createAsyncThunk<DeleteMembershipTypeResponse, bigint>('membershiptype/delete', async (id) => {
  const result = await fetch(`/.netlify/functions/membership_types/${id}`, {
    method: 'DELETE',
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<DeleteMembershipTypeResponse>
})

export const editMembershipType = createAsyncThunk<EditMembershipTypeResponse, { id: bigint } & z.infer<typeof EditMembershipTypeForm>>('membershiptype/edit', async (membership_type) => {
  const result = await fetch(`/.netlify/functions/membership_types/${membership_type.id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...membership_type, id: undefined }),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<EditMembershipTypeResponse>
})

export const addMembershipType = createAsyncThunk<AddMembershipTypeResponse, z.infer<typeof AddMembershipTypeForm>>('membershiptype/add', async (membership_type) => {
  const result = await fetch(`/.netlify/functions/membership_types`, {
    method: 'POST',
    body: JSON.stringify(membership_type),
  })
  if (!result.ok) {
    const error = await result.json() as Error
    throw new Error(error.message)
  }

  return await result.json() as Promise<AddMembershipTypeResponse>
})

export const membershipTypeSlice = createSlice({
  name: 'membershipType',
  initialState: {
    membership_types: [],
    count: 0,
    is_fetching: false,
    is_deleting: false,
    is_editing: false,
    is_adding: false,
    error_msg: '',
  } as MembershipTypeSliceState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembershipTypes.pending, (state) => {
        state.is_fetching = true
      })
      .addCase(fetchMembershipTypes.rejected, (state, action) => {
        state.is_fetching = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(fetchMembershipTypes.fulfilled, (state, action) => {
        state.is_fetching = false
        state.error_msg = ''
        state.membership_types = action.payload.membership_types
        state.count = action.payload.count
      })

      .addCase(deleteMembershipType.pending, (state) => {
        state.is_deleting = true
      })
      .addCase(deleteMembershipType.rejected, (state, action) => {
        state.is_deleting = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(deleteMembershipType.fulfilled, (state, action) => {
        state.is_deleting = false
        state.error_msg = ''

        const index = state.membership_types.findIndex((type) => type.id === action.payload?.id)
        if (index < 0) return

        const newMembershipTypes = [...state.membership_types]
        newMembershipTypes.splice(index, 1)

        state.membership_types = newMembershipTypes
        state.count = state.count - 1
      })

      .addCase(editMembershipType.pending, (state) => {
        state.is_editing = true
      })
      .addCase(editMembershipType.rejected, (state, action) => {
        state.is_editing = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(editMembershipType.fulfilled, (state, action) => {
        state.is_editing = false
        state.error_msg = ''

        const index = state.membership_types.findIndex((type) => type.id === action.payload?.id)
        if (index < 0) return

        const newMembershipTypes = [...state.membership_types]
        newMembershipTypes[index] = {
          ...newMembershipTypes[index],
          name: action.payload.name,
        }

        state.membership_types = newMembershipTypes
      })

      .addCase(addMembershipType.pending, (state) => {
        state.is_adding = true
      })
      .addCase(addMembershipType.rejected, (state, action) => {
        state.is_adding = false
        state.error_msg = action.error.message ?? ''
      })
      .addCase(addMembershipType.fulfilled, (state, action) => {
        state.is_adding = false
        state.error_msg = ''

        const newMembershipTypes = [
          ...state.membership_types,
          {
            ...action.payload,
            nbr_of_members: 0,
          }
        ]
        state.membership_types = newMembershipTypes
        state.count = state.count + 1
      })
  },
})

export default membershipTypeSlice.reducer
