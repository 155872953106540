import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'

import userReducer from './slices/userSlice'
import memberReducer from './slices/memberSlice'
import membershipTypesReducer from './slices/membershipTypeSlice'
import checkinSliceReducer from './slices/checkinSlice'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = configureStore({
  reducer: {
    user: userReducer,
    member: memberReducer,
    membership_type: membershipTypesReducer,
    checkin: checkinSliceReducer,
  },
  enhancers: [composedEnhancer],
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
