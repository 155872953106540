import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from '../store'
import type { MemberWithMembershipType } from '../../services/MemberService'
import type { MembershipTypeWithNbrOfMembers } from '../../services/MembershipService'
import { DateTime } from 'luxon'

import Modal from 'react-bootstrap/Modal'

export const BLOOD_GROUPS = ['A', 'B', 'AB', 'O']

export interface MemberAddEditModalFormState {
  membership_number: string
  name: string
  phone_number: string
  blood_group: string
  emergency_contact_name: string
  emergency_contact_phone_number: string
  membership_start_date: string
  membership_type_id: string
}

export const memberAddEditModalDefaultForm: MemberAddEditModalFormState = {
  membership_number: '',
  name: '',
  phone_number: '',
  blood_group: BLOOD_GROUPS[0],
  emergency_contact_name: '',
  emergency_contact_phone_number: '',
  membership_start_date: DateTime.now().toFormat('yyyy-MM-dd'),
  membership_type_id: '-1',
}

interface MemberAddEditModalProps {
  editing: bigint | null
  editingMember?: MemberWithMembershipType
  adding: boolean
  onHide: () => void
  onAddClicked: (form: MemberAddEditModalFormState) => void
  onEditClicked: (form: MemberAddEditModalFormState) => void
}

const MemberAddEditModal = ({ editing, editingMember, adding, onHide, onAddClicked, onEditClicked }: MemberAddEditModalProps) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const membership_types = useSelector<RootState, MembershipTypeWithNbrOfMembers[]>(state => state.membership_type.membership_types) ?? []

  const initialForm = useMemo(() => {
    if (adding || editing === null) {
      return {
        ...memberAddEditModalDefaultForm,
        membership_type_id: membership_types.length > 0 ? membership_types[0].id.toString() : '-1',
      }
    }

    return {
      membership_number: editingMember!.membership_number,
      name: editingMember!.name,
      phone_number: editingMember!.phone_number,
      blood_group: editingMember!.blood_group,
      emergency_contact_name: editingMember!.emergency_contact_name,
      emergency_contact_phone_number: editingMember!.emergency_contact_phone_number,
      membership_start_date: DateTime.fromISO(editingMember!.membership_start_date as unknown as string).toFormat('yyyy-MM-dd'),
      membership_type_id: editingMember!.membership_type_id.toString(),
    }
  }, [editing, adding, editingMember, membership_types])

  const [form, setForm] = useState<MemberAddEditModalFormState>(initialForm)
  useEffect(() => {
    setForm(initialForm)
  }, [editing, adding, editingMember, initialForm])

  return (
    <Modal show={editing !== null || adding} onHide={onHide}>
      <Modal.Header closeButton>
        {
          (() => {
            if (editing) {
              return <div>
                Editing
                <span className="text-warning"> {membership_types.find(m => m.id === editing)?.name}</span>
              </div>
            }

            return <>Adding a new member</>
          })()
        }
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-membership-number" className="form-label">No</label>
          <input
            type="text"
            className="form-control"
            id="edit-member-modal-membership-number"
            value={form.membership_number}
            onChange={(e) => setForm({ ...form, membership_number: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-name" className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            id="edit-member-modal-name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-phone-number" className="form-label">Phone number</label>
          <input
            type="text"
            className="form-control"
            id="edit-member-modal-phone-number"
            value={form.phone_number}
            onChange={(e) => setForm({ ...form, phone_number: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-blood-group" className="form-label">Blood group</label>
          <select className="form-select" id="edit-member-modal-blood-group" value={form.blood_group} onChange={(e) => setForm({ ...form, blood_group: e.target.value })}>
            {BLOOD_GROUPS.map(b => <option key={b} value={b}>{b}</option>)}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-emergency-contact-name" className="form-label">Emergency contact name</label>
          <input
            type="text"
            className="form-control"
            id="edit-member-modal-emergency-contact-name"
            value={form.emergency_contact_name}
            onChange={(e) => setForm({ ...form, emergency_contact_name: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-emergency-contact-phone-number" className="form-label">Emergency contact phone number</label>
          <input
            type="text"
            className="form-control"
            id="edit-member-modal-emergency-contact-phone-number"
            value={form.emergency_contact_phone_number}
            onChange={(e) => setForm({ ...form, emergency_contact_phone_number: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-membership-start-date" className="form-label">Membership start date</label>
          <input
            type="date"
            className="form-control"
            id="edit-member-modal-membership-start-date"
            value={form.membership_start_date}
            onChange={(e) => setForm({ ...form, membership_start_date: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="edit-member-modal-membership-type" className="form-label">Membership type</label>
          <select className="form-select" id="edit-member-modal-membership-type" value={form.membership_type_id} onChange={(e) => setForm({ ...form, membership_type_id: e.target.value })}>
            {membership_types.length === 0 && <option value="-1">None</option>}
            {membership_types.length > 0 && membership_types.map(m => <option key={m.id.toString()} value={m.id.toString()}>{m.name}</option>)}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <>
          <button className="btn btn-outline-secondary" onClick={onHide}>
            Cancel
          </button>
          {editing && <button className="btn btn-warning" onClick={() => onEditClicked(form)}>
            Edit
          </button>}
          {adding && <button className="btn btn-success" onClick={() => onAddClicked(form)}>
            Add
          </button>}
        </>
      </Modal.Footer>
    </Modal>
  )
}

export default MemberAddEditModal
