import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function Logout() {

    const navigate = useNavigate()

    useEffect(() => {

        // logout
        const logout = async () => {
          await fetch("/.netlify/functions/logout")
          navigate('/login')
        }
        logout()
    
      }, [navigate])

    return <></>
}
